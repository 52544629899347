"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * LiBat Gestion
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginResponseToJSON = exports.LoginResponseFromJSONTyped = exports.LoginResponseFromJSON = void 0;
const _1 = require("./");
function LoginResponseFromJSON(json) {
    return LoginResponseFromJSONTyped(json, false);
}
exports.LoginResponseFromJSON = LoginResponseFromJSON;
function LoginResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'auth': json['auth'],
        'message': json['message'],
        'token': json['token'],
        'user': _1.IUserFromJSON(json['user']),
    };
}
exports.LoginResponseFromJSONTyped = LoginResponseFromJSONTyped;
function LoginResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'auth': value.auth,
        'message': value.message,
        'token': value.token,
        'user': _1.IUserToJSON(value.user),
    };
}
exports.LoginResponseToJSON = LoginResponseToJSON;
