import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { IAccount, AccountApi, IAccountUpdate } from '@libat/api';
import { ApiConfig } from '@libat/shared';

const accountApi = new AccountApi(ApiConfig);

//////////////////////////////////////////////////////
// Actions
//////////////////////////////////////////////////////
const SET_ACCOUNTS = 'account/SET_ACCOUNTS';
const SET_LIST_REVERSED = 'account/SET_LIST_REVERSED';

//////////////////////////////////////////////////////
// Action Creators
//////////////////////////////////////////////////////
export const setAccountsAction = createAction(SET_ACCOUNTS)<IAccount[]>();

export const setListReversedAction = createAction(SET_LIST_REVERSED)<boolean>();

type RootAction = ActionType<
  typeof setAccountsAction | typeof setListReversedAction
>;

//////////////////////////////////////////////////////
// Reducer
//////////////////////////////////////////////////////
type AccountState = Readonly<{
  accounts: IAccount[];
  reverseList: boolean;
}>;

const initialState: AccountState = {
  accounts: [],
  reverseList: true,
};

const accountReducer = createReducer<AccountState, RootAction>(initialState)
  .handleAction(setAccountsAction, (state, action) => {
    return { ...state, accounts: action.payload };
  })
  .handleAction(setListReversedAction, (state, action) => {
    return { ...state, reverseList: action.payload };
  });

export default accountReducer;

//////////////////////////////////////////////////////
// Selectors
//////////////////////////////////////////////////////
export const accountsSelector = ({ account }: { account: AccountState }) =>
  account.accounts;

export const isListReversedSelector = ({
  account,
}: {
  account: AccountState;
}) => account.reverseList;

//////////////////////////////////////////////////////
// Action Helpers
//////////////////////////////////////////////////////
export function fetchAccounts() {
  return async (dispatch: any) => {
    try {
      const accounts = await accountApi.getAccounts();
      dispatch(setAccountsAction(accounts));
    } catch (e) {
      console.log(e);
    }
  };
}

export function updateAccount(update: IAccountUpdate) {
  return async (dispatch: any) => {
    try {
      await accountApi.updateAccount({ accountUpdate: update });
      dispatch(fetchAccounts());
    } catch (e) {
      console.log(e);
    }
  };
}

// export function startLogout() {
//   return async (dispatch: any) => {
//     try {
//       await userApi.logout();
//       dispatch(logout());
//     } catch (e) {
//       console.log(e);
//     }
//   }
// }
