import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import accountReducer from './modules/account';
import transactionReducer from './modules/transaction';
import userReducer from './modules/user';
import filterReducer from './modules/filter';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function () {
  return createStore(
    combineReducers({
      account: accountReducer,
      filter: filterReducer,
      transaction: transactionReducer,
      user: userReducer,
    }),
    composeEnhancers(applyMiddleware(thunk)),
  );
}
