"use strict";
exports.__esModule = true;
exports.round = exports.calculateTva = void 0;
function calculateTva(amount, tvaRate) {
    return amount - amount / (1.0 + (tvaRate / 100));
}
exports.calculateTva = calculateTva;
function round(value, precision) {
    var mult = Math.pow(10, precision);
    return Math.round(value * mult) / mult;
}
exports.round = round;
