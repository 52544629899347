import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { Colors } from '@blueprintjs/core';

import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import Account from '../pages/Account';

import { createBrowserHistory } from 'history';
import { PrivateRoute } from '../PrivateRoute';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCurrentUser,
  isAuthSelector,
  isDarkModeSelector,
  isFetchingCurrentUserDoneSelector,
} from '../../redux/modules/user';
import CreateInvoice from '../pages/CreateInvoice';

export const history = createBrowserHistory();

export default function AppRouter() {
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthSelector);
  const isFetchingDone = useSelector(isFetchingCurrentUserDoneSelector);
  const isDarkMode = useSelector(isDarkModeSelector);

  // Retrieve current user, if user is authenticated already
  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  const getHomeComponent = () => {
    if (!isFetchingDone) {
      return <React.Fragment />;
    } else if (isAuth) {
      return <Redirect to='/dashboard' />;
    } else {
      return <Login />;
    }
  };

  // console.log('Home component', isFetchingDone, isAuth);

  const getRoutes = () => {
    return (
      <div
        className={isDarkMode ? 'bp3-dark' : ''}
        style={{
          backgroundSize: 'cover',
          backgroundColor: isDarkMode ? Colors.DARK_GRAY3 : 'white',
          height: '100%',
        }}
      >
        <Switch>
          <Route exact={true} path='/' component={getHomeComponent} />
          <PrivateRoute path='/dashboard' component={Dashboard} />
          <PrivateRoute path='/invoice' component={CreateInvoice} />
          <PrivateRoute path='/account/:iban' component={Account} />
        </Switch>
      </div>
    );
  };

  return <Router history={history}>{getRoutes()}</Router>;
}
