"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * LiBat Gestion
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ITransactionToJSON = exports.ITransactionFromJSONTyped = exports.ITransactionFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ITransactionFromJSON(json) {
    return ITransactionFromJSONTyped(json, false);
}
exports.ITransactionFromJSON = ITransactionFromJSON;
function ITransactionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'accountId': !runtime_1.exists(json, 'accountId') ? undefined : json['accountId'],
        'account': !runtime_1.exists(json, 'account') ? undefined : _1.IAccountFromJSON(json['account']),
        'attachmentId': !runtime_1.exists(json, 'attachmentId') ? undefined : json['attachmentId'],
        'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
        'date': (new Date(json['date'])),
        'valueDate': (new Date(json['valueDate'])),
        'amount': json['amount'],
        'balance': json['balance'],
        'description': json['description'],
        'parentTransaction': ITransactionFromJSON(json['parentTransaction']),
        'parentTransactionId': !runtime_1.exists(json, 'parentTransactionId') ? undefined : json['parentTransactionId'],
        'childTransactions': !runtime_1.exists(json, 'childTransactions') ? undefined : (json['childTransactions'].map(ITransactionFromJSON)),
        'currency': json['currency'],
        'changeRate': json['changeRate'],
        'tvaRate': !runtime_1.exists(json, 'tvaRate') ? undefined : json['tvaRate'],
        'isInvestment': json['isInvestment'],
        'tva': !runtime_1.exists(json, 'tva') ? undefined : json['tva'],
        'tvaOverride': !runtime_1.exists(json, 'tvaOverride') ? undefined : json['tvaOverride'],
        'note': !runtime_1.exists(json, 'note') ? undefined : json['note'],
    };
}
exports.ITransactionFromJSONTyped = ITransactionFromJSONTyped;
function ITransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'accountId': value.accountId,
        'account': _1.IAccountToJSON(value.account),
        'attachmentId': value.attachmentId,
        'iban': value.iban,
        'date': (value.date.toISOString()),
        'valueDate': (value.valueDate.toISOString()),
        'amount': value.amount,
        'balance': value.balance,
        'description': value.description,
        'parentTransaction': ITransactionToJSON(value.parentTransaction),
        'parentTransactionId': value.parentTransactionId,
        'childTransactions': value.childTransactions === undefined ? undefined : (value.childTransactions.map(ITransactionToJSON)),
        'currency': value.currency,
        'changeRate': value.changeRate,
        'tvaRate': value.tvaRate,
        'isInvestment': value.isInvestment,
        'tva': value.tva,
        'tvaOverride': value.tvaOverride,
        'note': value.note,
    };
}
exports.ITransactionToJSON = ITransactionToJSON;
