import React, { useEffect } from 'react';
import { Header } from '../Header';
import { Colors } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { accountsSelector, fetchAccounts } from '../../redux/modules/account';
import AccountLink from '../AccountLink';
import { isDarkModeSelector } from '../../redux/modules/user';

export default function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccounts());
  }, [dispatch]);

  const isDarkMode = useSelector(isDarkModeSelector);

  // ToDo : Refresh account list after a csv import

  const accounts = useSelector(accountsSelector);

  function getAccountList() {
    if (accounts && accounts.length) {
      return accounts.map((el) => <AccountLink account={el} key={el.id} />);
    } else {
      return <div>Aucun compte n'existe. Veuillez importer un CSV.</div>;
    }
  }

  return (
    <div style={{ backgroundColor: isDarkMode ? Colors.DARK_GRAY3 : 'white' }}>
      <Header />

      <div style={{ padding: '10px' }}>
        <h2>Comptes</h2>
        {getAccountList()}
        {/*<TransactionList/>*/}
      </div>
    </div>
  );
}
