import { createSelector } from 'reselect';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import {
  AttachmentApi,
  ITransaction,
  ITransactionUpdate,
  TransactionApi,
} from '@libat/api';
import { ApiConfig, DEFAULT_TVA_RATE } from '@libat/shared';
import _ from 'lodash';
import { calculateTva } from '@libat/shared';
import { dateRangeSelector } from './filter';
import moment from 'moment';

const transactionApi = new TransactionApi(ApiConfig);
const attachmentApi = new AttachmentApi(ApiConfig);

//////////////////////////////////////////////////////
// Actions
//////////////////////////////////////////////////////
const REMOVE_ATTACHMENT = 'transaction/REMOVE_ATTACHMENT';
const SET_TRANSACTIONS = 'transaction/SET_TRANSACTIONS';
const UPDATE_TRANSACTIONS = 'transaction/UPDATE_TRANSACTIONS';

//////////////////////////////////////////////////////
// Action Creators
//////////////////////////////////////////////////////
const removeAttachmentAction = createAction(REMOVE_ATTACHMENT)<number>();

export const setTransactionsAction = createAction(SET_TRANSACTIONS)<
  ITransaction[]
>();

export const updateTransactionsAction = createAction(UPDATE_TRANSACTIONS)<
  ITransaction[]
>();

type RootAction = ActionType<
  | typeof removeAttachmentAction
  | typeof setTransactionsAction
  | typeof updateTransactionsAction
>;

//////////////////////////////////////////////////////
// Reducer
//////////////////////////////////////////////////////
type TransactionState = Readonly<{
  transactions: ITransaction[];
}>;

const initialState: TransactionState = {
  transactions: [],
};

const transactionReducer = createReducer<TransactionState, RootAction>(
  initialState,
)
  .handleAction(removeAttachmentAction, (state, action) => {
    const attachmentId = action.payload;
    const transactions = _.cloneDeep(state.transactions);

    const toUpdate = transactions.filter(
      (t) => t.attachmentId === attachmentId,
    );
    for (const transaction of toUpdate) {
      delete transaction.attachmentId;
    }

    return { ...state, transactions };
  })
  .handleAction(setTransactionsAction, (state, action) => {
    return { ...state, transactions: action.payload };
  })
  .handleAction(updateTransactionsAction, (state, action) => {
    const transactions = _.cloneDeep(state.transactions);

    for (const el of action.payload as ITransaction[]) {
      const index = transactions.findIndex((t) => t.id === el.id);

      if (index === -1) {
        continue;
      }

      transactions[index] = el;
    }

    return { ...state, transactions };
  });

export default transactionReducer;

//////////////////////////////////////////////////////
// Selectors
//////////////////////////////////////////////////////
export const transactionsSelector = ({
  transaction,
}: {
  transaction: TransactionState;
}) => transaction.transactions;

export function transactionByIdSelector(id: number) {
  return createSelector(transactionsSelector, (transactions) => {
    return transactions.find((t) => t.id === id);
  });
}

export function transactionsOfAccountSelector(iban: string) {
  return createSelector(transactionsSelector, (transactions) => {
    const transactionsFiltered = transactions.filter(
      (el) => el.account && !el.parentTransactionId && el.account.iban === iban.replace('--', '/'),
    );

    for (const tr of transactionsFiltered) {
      tr.childTransactions = transactions.filter(
        (el) => el.parentTransactionId === tr.id,
      );
      for (const child of tr.childTransactions) {
        child.parentTransaction = tr;
      }
    }

    return transactionsFiltered;
  });
}

export function totalsSelector(iban: string) {
  return createSelector(
    transactionsOfAccountSelector(iban),
    dateRangeSelector,
    (transactions, dateRange) => {
      const startDate = dateRange ? dateRange[0] : undefined;
      const endDate = dateRange ? dateRange[1] : undefined;

      const totals = {
        debit: 0.0,
        credit: 0.0,
        balance: 0.0,
        tvaDebit: 0.0,
        tvaCredit: 0.0,
        tvaInvestment: 0.0,
      };

      if (transactions.length > 0) {
        totals.balance = transactions[transactions.length - 1].balance;
      }

      for (const tr of transactions) {
        if (
          !startDate ||
          !endDate ||
          moment(tr.date).isBetween(startDate, endDate, 'day', '[]')
        ) {
          // We will keep the last balance of the list in the date range;
          totals.balance = tr.balance;

          if (tr.amount > 0) {
            totals.credit += tr.amount;
          } else {
            totals.debit += tr.amount;
          }

          if (tr?.childTransactions?.length) {
            for (const childTr of tr.childTransactions) {
              let tva = 0;
              if (
                childTr.tvaOverride !== undefined &&
                childTr.tvaOverride !== null
              ) {
                tva = childTr.tvaOverride;
              } else {
                tva = childTr.tva ?? 0;
              }

              if (childTr.isInvestment) {
                totals.tvaInvestment += tva;
              } else if (tva > 0) {
                totals.tvaCredit += tva;
              } else {
                totals.tvaDebit += tva;
              }
            }
          } else {
            let tva = 0;
            if (tr.tvaOverride !== undefined && tr.tvaOverride !== null) {
              tva = tr.tvaOverride;
            } else {
              tva = tr.tva ?? 0;
            }

            if (tr.isInvestment) {
              totals.tvaInvestment += tva;
            } else if (tva > 0) {
              totals.tvaCredit += tva;
            } else {
              totals.tvaDebit += tva;
            }
          }
        }
      }

      return totals;
    },
  );
}

export function tvaCreditSelector(id: number) {
  return createSelector(transactionByIdSelector(id), (transaction) => {
    if (!transaction?.childTransactions?.length) {
      if ((transaction?.amount ?? 0) < 0 || transaction?.isInvestment) {
        return 0.0;
      }

      if (
        transaction?.tvaOverride !== undefined &&
        transaction?.tvaOverride !== null
      ) {
        return transaction?.tvaOverride;
      }

      return transaction?.tva ?? 0;
    } else {
      let tva = 0.0;
      for (const child of transaction.childTransactions) {
        if (child.amount < 0 || child.isInvestment) {
          continue;
        }

        if (child.tvaOverride !== undefined && child.tvaOverride !== null) {
          tva += child.tvaOverride;
        } else {
          tva += calculateTva(child.amount, child.tvaRate ?? DEFAULT_TVA_RATE);
        }
      }

      return tva;
    }
  });
}

export function hasTvaOverrideSelector(id: number) {
  return createSelector(transactionByIdSelector(id), (transaction) => {
    return (
      transaction?.tvaOverride !== undefined &&
      transaction?.tvaOverride !== null
    );
  });
}

export function tvaDebitSelector(id: number) {
  return createSelector(transactionByIdSelector(id), (transaction) => {
    if (!transaction?.childTransactions?.length) {
      if ((transaction?.amount ?? 0) > 0 || transaction?.isInvestment) {
        return 0.0;
      }

      if (
        transaction?.tvaOverride !== undefined &&
        transaction?.tvaOverride !== null
      ) {
        return transaction?.tvaOverride;
      }

      return transaction?.tva ?? 0;
    } else {
      let tva = 0.0;
      for (const child of transaction.childTransactions) {
        if (child.amount > 0 || child.isInvestment) {
          continue;
        }

        if (child.tvaOverride !== undefined && child.tvaOverride !== null) {
          tva += child.tvaOverride;
        } else {
          tva += calculateTva(child.amount, child.tvaRate ?? DEFAULT_TVA_RATE);
        }
      }

      return tva;
    }
  });
}

export function tvaInvestmentSelector(id: number) {
  return createSelector(transactionByIdSelector(id), (transaction) => {
    if (!transaction?.childTransactions?.length) {
      if (!transaction?.isInvestment) {
        return 0.0;
      }

      if (
        transaction?.tvaOverride !== undefined &&
        transaction?.tvaOverride !== null
      ) {
        return transaction?.tvaOverride;
      }

      return transaction?.tva ?? 0;
    } else {
      let tva = 0.0;
      for (const child of transaction.childTransactions) {
        if (!child.isInvestment) {
          continue;
        }

        if (child.tvaOverride !== undefined && child.tvaOverride !== null) {
          tva += child.tvaOverride;
        } else {
          tva += calculateTva(child.amount, child.tvaRate ?? DEFAULT_TVA_RATE);
        }
      }

      return tva;
    }
  });
}

//////////////////////////////////////////////////////
// Action Helpers
//////////////////////////////////////////////////////
export function fetchTransactionById(id: number) {
  return async (dispatch: any) => {
    try {
      const transaction = await transactionApi.getTransactionById({ id });
      dispatch(updateTransactions([transaction]));
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchTransactions(iban: string) {
  return async (dispatch: any) => {
    try {
      // console.log(iban);
      const transactions = await transactionApi.getTransactionsOf({ iban: iban.replace('/', '--') });
      for (const tr of transactions) {
        tr.amount = Number(tr.amount);
        tr.balance = Number(tr.balance);
        if (tr.tvaRate) tr.tvaRate = Number(tr.tvaRate);
        if (tr.tva) tr.tva = Number(tr.tva);
        if (tr.tvaOverride) tr.tvaOverride = Number(tr.tvaOverride);
      }
      // console.log(transactions);

      dispatch(setTransactionsAction(transactions));
    } catch (e) {
      console.log(e);
    }
  };
}

export function updateTransactions(updates: ITransactionUpdate[]) {
  return async (dispatch: any) => {
    try {
      const transactions = await transactionApi.updateTransactions({
        transactionUpdates: updates,
      });
      // console.log(transactions);
      dispatch(updateTransactionsAction(transactions));
    } catch (e) {
      console.log(e);
    }
  };
}

export function removeAttachmentById(attachmentId: number) {
  return async (dispatch: any) => {
    try {
      dispatch(removeAttachmentAction(attachmentId));
      await attachmentApi.deleteAttachment({ id: attachmentId });
    } catch (e) {
      console.log(e);
    }
  };
}

export function exportTransactionsAsPdf(
  startDate: Date,
  endDate: Date,
  iban: string,
) {
  return async (dispatch: any) => {
    try {
      await transactionApi.exportTransactionsAsPdf({
        startDate,
        endDate,
        iban,
      });
    } catch (e) {
      console.log(e);
    }
  };
}
// export function startLogout() {
//   return async (dispatch: any) => {
//     try {
//       await userApi.logout();
//       dispatch(logout());
//     } catch (e) {
//       console.log(e);
//     }
//   }
// }
