import React, { createRef, SyntheticEvent } from 'react';
import { AttachmentApi, ITransaction, ITransactionUpdate } from '@libat/api';
import { Checkbox, EditableText, Icon, MenuDivider } from '@blueprintjs/core';
import {
  getColumnWidth,
  TransactionColumn,
  DEFAULT_TVA_RATE,
  ApiConfig,
} from '@libat/shared';
import {
  hasTvaOverrideSelector,
  removeAttachmentById,
  transactionByIdSelector,
  tvaCreditSelector,
  tvaDebitSelector,
  tvaInvestmentSelector,
  updateTransactions,
} from '../redux/modules/transaction';
import { useDispatch, useSelector } from 'react-redux';
import withDataSubmitTimer from './withDataSubmitTimer';

import { ContextMenu, Menu, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { currentUserSelector, isDarkModeSelector } from '../redux/modules/user';

// const RightClickMe = ContextMenuTarget(
//   class RightClickMeWithContext extends React.Component {
//     render() {
//       // root element must support `onContextMenu`
//       return <div>TEST</div>;
//     }
//
//     renderContextMenu() {
//       // return a single element, or nothing to use default browser behavior
//       return (
//         <Menu>
//           <MenuItem text="Save" />
//           <MenuItem text="Delete" />
//         </Menu>
//       );
//     }
//
//     onContextMenuClose() {
//       // Optional method called once the context menu is closed.
//     }
//   }
// );

function TransactionMenu({
  transaction,
  uploadAttachment,
  removeAttachment,
  resetTvaOverride,
}: {
  transaction: ITransaction;
  uploadAttachment: (e: SyntheticEvent) => void;
  removeAttachment: (e: SyntheticEvent) => void;
  resetTvaOverride: (e: SyntheticEvent) => void;
}) {
  return (
    <Menu>
      <MenuItem
        text='Attacher Facture'
        icon={IconNames.PAPERCLIP}
        onClick={uploadAttachment}
      />
      <MenuItem
        intent='danger'
        text='Supprimer Facture'
        icon={IconNames.TRASH}
        onClick={removeAttachment}
        disabled={!transaction.attachmentId}
      />

      <MenuDivider />

      <MenuItem
        text='Reinitialiser TVA Manuelle'
        icon={IconNames.RESET}
        onClick={resetTvaOverride}
        disabled={
          transaction.tvaOverride === null ||
          transaction.tvaOverride === undefined
        }
      />
    </Menu>
  );
}

export default function TransactionRow({
  transactionId,
  isParentTransaction,
  hasChildren,
}: {
  transactionId: number;
  isParentTransaction: boolean;
  hasChildren?: boolean;
}) {
  if (!isParentTransaction) {
    hasChildren = false;
  }

  const inputFile = createRef<HTMLInputElement>();

  const transaction: ITransaction | undefined = useSelector(
    transactionByIdSelector(transactionId),
  );

  const tvaDebit = useSelector(tvaDebitSelector(transaction?.id ?? 0));
  const hasTvaOverride = useSelector(
    hasTvaOverrideSelector(transaction?.id ?? 0),
  );
  const tvaCredit = useSelector(tvaCreditSelector(transaction?.id ?? 0));
  const tvaInvestment = useSelector(
    tvaInvestmentSelector(transaction?.id ?? 0),
  );

  const dispatch = useDispatch();

  const isDarkMode = useSelector(isDarkModeSelector);
  const user = useSelector(currentUserSelector);

  const NoteInput = withDataSubmitTimer(EditableText, onNoteChange);
  const TvaRateInput = withDataSubmitTimer('input', onTvaChange);
  const TvaCreditInput = withDataSubmitTimer(
    EditableText,
    onTvaCreditOverrideChange,
  );
  const TvaDebitInput = withDataSubmitTimer(
    EditableText,
    onTvaDebitOverrideChange,
  );

  function resetTvaOverride(e: SyntheticEvent) {
    if (!hasTvaOverride || !transaction) {
      return;
    }

    const update: ITransactionUpdate = {
      id: transaction.id,
      tvaOverride: null!,
    };
    dispatch(updateTransactions([update]));
  }

  function uploadAttachment(e: SyntheticEvent) {
    if (inputFile.current) {
      inputFile.current.click();
    }
  }

  function removeAttachment(e: SyntheticEvent) {
    if (!transaction || !transaction.attachmentId) {
      return;
    }

    dispatch(removeAttachmentById(transaction.attachmentId));
  }

  async function onFileUpload(e: SyntheticEvent) {
    if (!transaction) {
      return;
    }

    if (inputFile.current) {
      const files = inputFile.current.files;

      if (files && files.length) {
        console.log(inputFile.current.files);
        const attachmentApi = new AttachmentApi(ApiConfig);

        try {
          const attachment = await attachmentApi.uploadAttachment({
            uploadedFile: files[0],
            transactionId,
          });

          console.log('attachment', attachment);

          dispatch(
            updateTransactions([
              { id: transaction.id, attachmentId: attachment.id },
            ]),
          );
        } catch (e) {
          console.log(e);
        }
        // console.log(inputFile.current.files[0]);
        // const transactionApi = new TransactionApi(ApiConfig);
        //
        // try {
        //   await transactionApi.importTransactions({ uploadedFile: files[0] });
        // } catch (e) {
        //   console.log(e);
        // }
      }
    }
  }

  function openContextMenu(e: any) {
    e.preventDefault();

    if (!transaction) {
      return;
    }

    ContextMenu.show(
      <TransactionMenu
        transaction={transaction}
        uploadAttachment={uploadAttachment}
        removeAttachment={removeAttachment}
        resetTvaOverride={resetTvaOverride}
      />,
      { left: e.clientX, top: e.clientY },
      () => {
        // menu was closed; callback optional
      },
      true,
    );
  }

  function handleIsInvestmentChange() {
    if (!transaction) {
      return;
    }

    // setState({ ...state, isInvestment: !state.isInvestment });

    const update: ITransactionUpdate = {
      id: transaction.id,
      isInvestment: !transaction.isInvestment,
    };
    dispatch(updateTransactions([update]));
  }

  function onTvaDebitOverrideChange(target: any) {
    if (!transaction) {
      return;
    }

    let val = parseFloat(target);
    if (!val) {
      val = 0;
    } else if (val > 0) {
      val *= -1;
    }

    const update: ITransactionUpdate = { id: transaction.id, tvaOverride: val };
    dispatch(updateTransactions([update]));
  }

  function onTvaCreditOverrideChange(target: any) {
    if (!transaction) {
      return;
    }

    let val = parseFloat(target);
    if (!val) {
      val = 0;
    } else if (val < 0) {
      val *= -1;
    }

    const update: ITransactionUpdate = { id: transaction.id, tvaOverride: val };
    dispatch(updateTransactions([update]));
  }

  function onNoteChange(target: any) {
    if (!transaction) {
      return;
    }

    const update: ITransactionUpdate = { id: transaction.id, note: target };
    dispatch(updateTransactions([update]));
  }

  function onTvaChange(target: any) {
    if (!transaction) {
      return;
    }

    let val = parseFloat(target.value);
    if (!val) {
      val = 0;
    }

    // setState({ ...state, tvaRate: val });

    const update: ITransactionUpdate = { id: transaction.id, tvaRate: val };
    dispatch(updateTransactions([update]));
  }

  function getTvaCreditElement() {
    const hasChildren =
      transaction &&
      transaction.childTransactions &&
      transaction.childTransactions?.length > 0;
    if (!transaction || transaction?.amount < 0) {
      return <React.Fragment />;
    }

    if (hasChildren) {
      return (
        <React.Fragment>{Math.round(tvaCredit * 100) / 100}</React.Fragment>
      );
    } else if (user?.isVisitor ?? true) {
      return <div>{(Math.round(tvaCredit * 100) / 100).toString()}</div>;
    } else {
      return (
        <TvaCreditInput
          type='number'
          defaultValue={(Math.round(tvaCredit * 100) / 100).toString()}
          placeholder=''
        />
      );
    }
  }

  function getNoteElement() {
    const hasChildren =
      transaction &&
      transaction.childTransactions &&
      transaction.childTransactions?.length > 0;
    if (
      !transaction
      || hasChildren
    ) {
      return <React.Fragment />;
    }

    if (user?.isVisitor ?? true) {
      return <div>{transaction.note}</div>;
    } else {
      return (
        <NoteInput
          defaultValue={transaction.note}
          placeholder='...'
        />
      );
    }
  }

  function getTvaDebitElement() {
    const hasChildren =
      transaction &&
      transaction.childTransactions &&
      transaction.childTransactions?.length > 0;
    if (
      !transaction ||
      transaction?.amount > 0 ||
      transaction.isInvestment ||
      (hasChildren && !tvaDebit)
    ) {
      return <React.Fragment />;
    }

    if (hasChildren) {
      return (
        <React.Fragment>{Math.round(tvaDebit * 100) / 100}</React.Fragment>
      );
    } else if (user?.isVisitor ?? true) {
      return <div>{(Math.round(tvaDebit * 100) / 100).toString()}</div>;
    } else {
      return (
        <TvaDebitInput
          type='number'
          defaultValue={(Math.round(tvaDebit * 100) / 100).toString()}
          placeholder=''
        />
      );
    }
  }

  function getTvaInvestmentElement() {
    const hasChildren =
      transaction &&
      transaction.childTransactions &&
      transaction.childTransactions?.length > 0;
    if (
      !transaction ||
      !transaction.isInvestment ||
      (hasChildren && !tvaInvestment)
    ) {
      return <React.Fragment />;
    }

    if (hasChildren) {
      return (
        <React.Fragment>{Math.round(tvaInvestment * 100) / 100}</React.Fragment>
      );
    } else if (user?.isVisitor ?? true) {
      return <div>{(Math.round(tvaInvestment * 100) / 100).toString()}</div>;
    } else {
      return (
        <TvaDebitInput
          type='number'
          defaultValue={(Math.round(tvaInvestment * 100) / 100).toString()}
          placeholder=''
        />
      );
    }
  }

  function getTvaRateElement() {
    const style = {
      width: getColumnWidth(TransactionColumn.TvaCheckBox) + 'px',
      minWidth: getColumnWidth(TransactionColumn.TvaCheckBox) + 'px',
    };

    if (!hasChildren) {
      if (user?.isVisitor ?? true) {
        if (!hasTvaOverride) {
          return (
            <div style={{ ...style, textAlign: 'center' }}>
              {transaction?.tvaRate ?? DEFAULT_TVA_RATE}
            </div>
          );
        } else {
          return <div style={style} />;
        }
      } else {
        return (
          <TvaRateInput
            className='bp3-input'
            disabled={hasTvaOverride}
            style={{
              ...style,
              height: '20px',
            }}
            defaultValue={transaction?.tvaRate ?? DEFAULT_TVA_RATE}
          />
        );
      }
    } else {
      return <div style={style} />;
    }
  }

  if (!transaction) {
    return <div>Loading {transactionId}</div>;
  }

  // console.log(data);
  return (
    <div
      className={`transactionRow ${
        isParentTransaction
          ? isDarkMode
            ? ''
            : ' transactionRow__parent__light'
          : ` ${
              isDarkMode
                ? 'transactionRow__child'
                : 'transactionRow__child__light'
            }`
      }`}
      // style={isParentTransaction ? {} : { color: 'lightslategrey' }}
      onContextMenu={user?.isVisitor ?? true ? undefined : openContextMenu}
    >
      <input
        type='file'
        id='file'
        ref={inputFile}
        // style={{ display: 'none' }}
        onChange={onFileUpload}
        hidden={true}
      />

      {/* <div style={{width: '200px'}}>{data.iban}</div> */}
      <div
        style={{
          width: getColumnWidth(TransactionColumn.Date) + 'px',
          minWidth: getColumnWidth(TransactionColumn.Date),
        }}
      >
        {isParentTransaction ? (
          transaction.date.toLocaleDateString('fr-FR')
        ) : (
          <React.Fragment />
        )}
      </div>
      <div
        style={{
          width: getColumnWidth(TransactionColumn.ValueDate) + 'px',
          minWidth: getColumnWidth(TransactionColumn.ValueDate),
        }}
      >
        {isParentTransaction ? (
          transaction.valueDate.toLocaleDateString('fr-FR')
        ) : (
          <React.Fragment />
        )}
      </div>
      <div
        style={{
          // width: getColumnWidth(TransactionColumn.Description) + 'px',
          flexGrow: 1,
          minWidth: '100px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {transaction && transaction.attachmentId ? (
          <a
            href={'/api/v1/attachment/' + transaction.attachmentId}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon
              icon={IconNames.PAPERCLIP}
              intent='primary'
              style={{ marginRight: '5px', cursor: 'pointer' }}
            />
          </a>
        ) : (
          <React.Fragment />
        )}
        <div>{transaction.description}</div>
      </div>
      <div
        style={{
          width: getColumnWidth(TransactionColumn.Note) + 'px',
          minWidth: getColumnWidth(TransactionColumn.Note) + 'px',
          textAlign: 'left',
          fontStyle: 'italic',
        }}
      >
        {getNoteElement()}
      </div>
      <div
        style={{
          width: getColumnWidth(TransactionColumn.Debit) + 'px',
          minWidth: getColumnWidth(TransactionColumn.Debit) + 'px',
          textAlign: 'center',
        }}
      >
        {transaction.amount < 0
          ? Math.round(transaction.amount * 100) / 100
          : ''}
      </div>
      <div
        style={{
          width: getColumnWidth(TransactionColumn.Credit) + 'px',
          minWidth: getColumnWidth(TransactionColumn.Credit) + 'px',
          textAlign: 'center',
        }}
      >
        {transaction.amount > 0
          ? Math.round(transaction.amount * 100) / 100
          : ''}
      </div>
      <div
        style={{
          width: getColumnWidth(TransactionColumn.Balance) + 'px',
          minWidth: getColumnWidth(TransactionColumn.Balance) + 'px',
          textAlign: 'center',
        }}
      >
        {isParentTransaction ? (
          Math.round(transaction.balance * 100) / 100
        ) : (
          <React.Fragment />
        )}
      </div>

      <div
        style={{
          width: getColumnWidth(TransactionColumn.TvaDebit) + 'px',
          minWidth: getColumnWidth(TransactionColumn.TvaDebit) + 'px',
          textAlign: 'center',
        }}
      >
        {getTvaDebitElement()}
      </div>

      <div
        style={{
          width: getColumnWidth(TransactionColumn.TvaCredit) + 'px',
          minWidth: getColumnWidth(TransactionColumn.TvaCredit) + 'px',
          textAlign: 'center',
        }}
      >
        {getTvaCreditElement()}
      </div>

      <div
        style={{
          width: getColumnWidth(TransactionColumn.TvaInvestment) + 'px',
          minWidth: getColumnWidth(TransactionColumn.TvaInvestment) + 'px',
          textAlign: 'center',
        }}
      >
        {getTvaInvestmentElement()}
      </div>

      {/* <select style={{width: '50px'}}>
                <option>7.7</option>
                <option>0</option>
                <option>100</option>
            </select> */}

      {getTvaRateElement()}

      {transaction.amount < 0 && !hasChildren ? (
        <Checkbox
          style={{
            width: getColumnWidth(TransactionColumn.TvaPercent) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaPercent) + 'px',
            textAlign: 'center',
            marginBottom: 0,
            paddingBottom: 0,
          }}
          disabled={user?.isVisitor ?? true}
          checked={transaction.isInvestment}
          onChange={handleIsInvestmentChange}
        />
      ) : (
        <div
          style={{
            width: getColumnWidth(TransactionColumn.TvaPercent) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaPercent) + 'px',
          }}
        />
      )}
    </div>
  );
}
