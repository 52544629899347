"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * LiBat Gestion
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IUserToJSON = exports.IUserFromJSONTyped = exports.IUserFromJSON = void 0;
function IUserFromJSON(json) {
    return IUserFromJSONTyped(json, false);
}
exports.IUserFromJSON = IUserFromJSON;
function IUserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'isVisitor': json['isVisitor'],
    };
}
exports.IUserFromJSONTyped = IUserFromJSONTyped;
function IUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'isVisitor': value.isVisitor,
    };
}
exports.IUserToJSON = IUserToJSON;
