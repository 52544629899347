import { IInvoiceParams, InvoiceApi } from '@libat/api';
import { ApiConfig } from '@libat/shared';

const invoiceApi = new InvoiceApi(ApiConfig);

export async function createInvoice(invoiceParams: IInvoiceParams) {
  try {
    return await invoiceApi.createInvoice({ invoiceParams });
  } catch (e) {
    console.log(e);
  }
}
