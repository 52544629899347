"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * LiBat Gestion
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ITransactionUpdateToJSON = exports.ITransactionUpdateFromJSONTyped = exports.ITransactionUpdateFromJSON = void 0;
const runtime_1 = require("../runtime");
function ITransactionUpdateFromJSON(json) {
    return ITransactionUpdateFromJSONTyped(json, false);
}
exports.ITransactionUpdateFromJSON = ITransactionUpdateFromJSON;
function ITransactionUpdateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'attachmentId': !runtime_1.exists(json, 'attachmentId') ? undefined : json['attachmentId'],
        'tvaRate': !runtime_1.exists(json, 'tvaRate') ? undefined : json['tvaRate'],
        'isInvestment': !runtime_1.exists(json, 'isInvestment') ? undefined : json['isInvestment'],
        'tvaOverride': !runtime_1.exists(json, 'tvaOverride') ? undefined : json['tvaOverride'],
        'note': !runtime_1.exists(json, 'note') ? undefined : json['note'],
    };
}
exports.ITransactionUpdateFromJSONTyped = ITransactionUpdateFromJSONTyped;
function ITransactionUpdateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'attachmentId': value.attachmentId,
        'tvaRate': value.tvaRate,
        'isInvestment': value.isInvestment,
        'tvaOverride': value.tvaOverride,
        'note': value.note,
    };
}
exports.ITransactionUpdateToJSON = ITransactionUpdateToJSON;
