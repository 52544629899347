import React, { useState } from 'react';
import { Button, Colors, NumericInput } from '@blueprintjs/core';
import { Header } from '../Header';
import { useSelector } from 'react-redux';
import {
  currentUserSelector,
  isDarkModeSelector,
} from '../../redux/modules/user';
import { Redirect } from 'react-router-dom';
import { createInvoice } from '../../redux/modules/invoice';
import { IInvoiceParams } from '@libat/api';
import * as countries from 'i18n-iso-countries';
import StringSelector from '../StringSelector';
import { IconNames } from '@blueprintjs/icons';
import { Intent } from '@blueprintjs/core/lib/esm/common/intent';
import { AppToaster } from '../AppToaster';
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

interface CreateInvoiceState {
  account: string;
  amount: number;
  buildingNumber: number,
  currency: string;

  name: string;
  address: string;
  zip: number;
  city: string;
  country: string;
  countryCode: string;
  reference: string;
}

const initialState: CreateInvoiceState = {
  account: 'LiBat',
  address: '',
  buildingNumber: 0,
  city: '',
  countryCode: 'CH',
  country: 'Suisse',
  currency: 'CHF',
  name: '',
  zip: 0,
  amount: 0,
  reference: '',
};

export default function CreateInvoice() {
  const [state, setState] = useState<CreateInvoiceState>(initialState);

  const isDarkMode = useSelector(isDarkModeSelector);
  const user = useSelector(currentUserSelector);

  if (user?.isVisitor) {
    return <Redirect to='/dashboard' />;
  }

  async function onCreate() {
    if (!state.amount) {
      return AppToaster.error('Veuillez entrer un montant.');
    }

    if (!state.name) {
      return AppToaster.error('Veuillez entrer un nom.');
    }

    if (!state.address) {
      return AppToaster.error('Veuillez entrer une addresse.');
    }

    if (!state.buildingNumber) {
      return AppToaster.error('Veuillez entrer un numero de rue.');
    }

    if (!state.zip) {
      return AppToaster.error('Veuillez entrer un code postal.');
    }

    if (!state.city) {
      return AppToaster.error('Veuillez entrer une ville.');
    }

    const params: IInvoiceParams = {
      account: state.account,
      address: state.address,
      buildingNumber: state.buildingNumber,
      amount: state.amount,
      city: state.city,
      country: state.countryCode,
      currency: state.currency,
      name: state.name,
      zip: state.zip,
      reference: state.reference,
    };

    const id = await createInvoice(params);
    if (id) {
      const url = `/api/v1/invoice/${id}`;
      window.open(url, '_blank');
    }
  }

  function reset() {
    setState({ ...initialState });
  }

  function onCountryChange(country: string) {
    const countryCode = countries.getAlpha2Code(country, 'fr');
    setState({ ...state, countryCode, country });
  }

  function onAccountChange(account: string) {
    setState({ ...state, account });
  }

  function onCurrencyChange(currency: string) {
    setState({ ...state, currency });
  }

  function onChange(field: string) {
    return (e: any) => {
      const value: string = e.target.value;

      if (field === 'name') {
        setState({ ...state, name: value });
      } else if (field === 'address') {
        setState({ ...state, address: value });
      } else if (field === 'city') {
        setState({ ...state, city: value });
      } else if (field === 'reference') {
        setState({ ...state, reference: value.replace(' ', '') });
      } else {
        console.error('Field not implemented :', field);
      }
    };
  }

  function onAmountChange(amount: number) {
    setState({ ...state, amount });
  }

  function onZipChange(zip: number) {
    setState({ ...state, zip });
  }

  function onBuildingNumberChange(buildingNumber: number) {
    setState({ ...state, buildingNumber });
  }

  const titleStyle = { minWidth: 100, width: 100 };
  const sectionStyle = { marginBottom: 5 };
  const inputStyle = { minWidth: 180, width: 180 };

  return (
    <div style={{ backgroundColor: isDarkMode ? Colors.DARK_GRAY3 : 'white' }}>
      <Header />

      <div style={{ padding: '10px' }}>
        <h2>Creer QR Code</h2>

        <div>
          <div className='flex-row' style={sectionStyle}>
            <div style={titleStyle}>Compte</div>
            <StringSelector
              style={inputStyle}
              items={['LiBat', 'Cadist', 'AV38 Glovelier', 'HDV49 La-Chaux-de-Fonds', 'Chavon Dessus 2 JU', 'Chavons Dessus']}
              defaultValue={state.account}
              onChange={onAccountChange}
              filterable={false}
            />
          </div>
        </div>

        <div>
          <div className='flex-row' style={sectionStyle}>
            <div style={titleStyle}>Montant</div>
            <NumericInput
              style={inputStyle}
              placeholder='Montant'
              buttonPosition='none'
              onValueChange={onAmountChange}
              value={state.amount === 0 ? undefined : state.amount}
            />
            <div style={{ minWidth: 5, width: 5 }} />
            <StringSelector
              style={{ minWidth: 70, width: 70 }}
              items={['CHF', 'EUR']}
              defaultValue={state.currency}
              onChange={onCurrencyChange}
              filterable={false}
            />
          </div>

          <div className='flex-row' style={sectionStyle}>
            <div style={titleStyle}>Nom</div>
            <input
              style={inputStyle}
              onChange={onChange('name')}
              value={state.name ?? ''}
              className='bp3-input'
              type='text'
              placeholder='Nom'
              data-lpignore='true'
              dir='auto'
            />
          </div>

          <div className='flex-row' style={sectionStyle}>
            <div style={titleStyle}>Reference</div>
            <input
              style={inputStyle}
              onChange={onChange('reference')}
              value={state.reference ?? ''}
              className='bp3-input'
              type='text'
              placeholder='Reference'
              data-lpignore='true'
              dir='auto'
            />
          </div>

          <div className='flex-row' style={sectionStyle}>
            <div style={titleStyle}>Addresse</div>
            <input
              style={inputStyle}
              onChange={onChange('address')}
              value={state.address ?? ''}
              className='bp3-input'
              type='text'
              placeholder='Addresse'
              data-lpignore='true'
              dir='auto'
            />
            <div style={{ minWidth: 5, width: 5 }} />
            <NumericInput
              style={{ minWidth: 70, width: 70 }}
              placeholder='No'
              buttonPosition='none'
              onValueChange={onBuildingNumberChange}
              value={state.buildingNumber === 0 ? undefined : state.buildingNumber}
            />
          </div>

          <div className='flex-row' style={sectionStyle}>
            <div style={titleStyle}>Code Postal</div>
            <NumericInput
              style={inputStyle}
              placeholder='Code Postal'
              buttonPosition='none'
              onValueChange={onZipChange}
              value={state.zip === 0 ? undefined : state.zip}
            />
          </div>

          <div className='flex-row' style={sectionStyle}>
            <div style={titleStyle}>Ville</div>
            <input
              style={inputStyle}
              onChange={onChange('city')}
              value={state.city ?? ''}
              className='bp3-input'
              type='text'
              placeholder='Ville'
              data-lpignore='true'
              dir='auto'
            />
          </div>

          <div className='flex-row' style={sectionStyle}>
            <div style={titleStyle}>Pays</div>
            <StringSelector
              style={{ width: 180, minWidth: 180 }}
              items={Object.values(countries.getNames('fr'))}
              defaultValue={state.country}
              onChange={onCountryChange}
              filterable={true}
            />
          </div>

          <div className='flex-row'>
            <div style={titleStyle} />
            <Button
              text={'Creer'}
              style={{ width: 88, minWidth: 88 }}
              onClick={onCreate}
              icon={IconNames.SAVED}
              intent={Intent.PRIMARY}
            />
            <div style={{ minWidth: 4, width: 4 }} />
            <Button
              text={'Reset'}
              style={{ width: 88, minWidth: 88 }}
              onClick={reset}
              icon={IconNames.RESET}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
