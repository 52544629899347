import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  isAuthSelector,
  isFetchingCurrentUserDoneSelector,
} from '../redux/modules/user';

export function PrivateRoute({
  path,
  component: Component,
}: {
  path: string;
  component: React.FC;
}) {
  const isAuth = useSelector(isAuthSelector);
  const isFetchingDone = useSelector(isFetchingCurrentUserDoneSelector);

  const getComponent = () => {
    // console.log('IS FETCHING DONE', isFetchingDone);
    // console.log('IS AUTH', isAuth);

    if (!isFetchingDone) {
      return <React.Fragment />;
    } else if (!isAuth) {
      return <Redirect to='/' />;
    } else {
      return <Component />;
    }
  };

  // console.log(getComponent());

  return <Route path={path} component={getComponent} />;
}
