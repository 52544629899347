import { IAccount } from '@libat/api';
import { Link } from 'react-router-dom';
import React from 'react';
import { updateAccount } from '../redux/modules/account';
import withDataSubmitTimer from './withDataSubmitTimer';
import { EditableText } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector } from '../redux/modules/user';

interface AccountLinkProps {
  account: IAccount;
}

export default function AccountLink({ account }: AccountLinkProps) {
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector);

  function onDescriptionChange(value: any) {
    dispatch(updateAccount({ id: account.id, description: value }));
  }

  const Description = withDataSubmitTimer(EditableText, onDescriptionChange);

  return (
    <div
      className='highlight-hover'
      key={account.iban}
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      <Link style={{ width: 200 }} to={'/account/' + account.iban.replace('/', '--')}>
        {account.iban}
      </Link>

      {user?.isVisitor ?? true ? (
        <div>{account.description}</div>
      ) : (
        <Description
          defaultValue={account.description}
          placeholder='Description'
        />
      )}
    </div>
  );
}
