"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * LiBat Gestion
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class TransactionApi extends runtime.BaseAPI {
    /**
     * Export a pdf from a list of transactions id
     */
    exportTransactionsAsPdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.iban === null || requestParameters.iban === undefined) {
                throw new runtime.RequiredError('iban', 'Required parameter requestParameters.iban was null or undefined when calling exportTransactionsAsPdf.');
            }
            if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
                throw new runtime.RequiredError('startDate', 'Required parameter requestParameters.startDate was null or undefined when calling exportTransactionsAsPdf.');
            }
            if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
                throw new runtime.RequiredError('endDate', 'Required parameter requestParameters.endDate was null or undefined when calling exportTransactionsAsPdf.');
            }
            const queryParameters = {};
            if (requestParameters.iban !== undefined) {
                queryParameters['iban'] = requestParameters.iban;
            }
            if (requestParameters.startDate !== undefined) {
                queryParameters['startDate'] = requestParameters.startDate.toISOString().substr(0, 10);
            }
            if (requestParameters.endDate !== undefined) {
                queryParameters['endDate'] = requestParameters.endDate.toISOString().substr(0, 10);
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/transactions/export`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Export a pdf from a list of transactions id
     */
    exportTransactionsAsPdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.exportTransactionsAsPdfRaw(requestParameters);
        });
    }
    /**
     * Get a single transaction by id
     */
    getTransactionByIdRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getTransactionById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/transaction/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ITransactionFromJSON(jsonValue));
        });
    }
    /**
     * Get a single transaction by id
     */
    getTransactionById(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getTransactionByIdRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Get all transactions from the DB for a specific IBAN
     */
    getTransactionsOfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.iban === null || requestParameters.iban === undefined) {
                throw new runtime.RequiredError('iban', 'Required parameter requestParameters.iban was null or undefined when calling getTransactionsOf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/transactions/{iban}`.replace(`{${"iban"}}`, encodeURIComponent(String(requestParameters.iban))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ITransactionFromJSON));
        });
    }
    /**
     * Get all transactions from the DB for a specific IBAN
     */
    getTransactionsOf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getTransactionsOfRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Import transactions from a CSV file
     */
    importTransactionsRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const consumes = [
                { contentType: 'multipart/form-data' },
            ];
            // @ts-ignore: canConsumeForm may be unused
            const canConsumeForm = runtime.canConsumeForm(consumes);
            let formParams;
            let useForm = false;
            // use FormData to transmit files using content-type "multipart/form-data"
            useForm = canConsumeForm;
            if (useForm) {
                formParams = new FormData();
            }
            else {
                formParams = new URLSearchParams();
            }
            if (requestParameters.uploadedFile !== undefined) {
                formParams.append('uploadedFile', requestParameters.uploadedFile);
            }
            const response = yield this.request({
                path: `/transactions/import`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: formParams,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Import transactions from a CSV file
     */
    importTransactions(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.importTransactionsRaw(requestParameters);
        });
    }
    /**
     * Submit transaction edits
     */
    updateTransactionsRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/transactions`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.transactionUpdates.map(models_1.ITransactionUpdateToJSON),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ITransactionFromJSON));
        });
    }
    /**
     * Submit transaction edits
     */
    updateTransactions(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateTransactionsRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.TransactionApi = TransactionApi;
