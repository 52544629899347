import React from 'react';
import { Colors, Switch } from '@blueprintjs/core';
import { Header } from '../Header';
import { useParams } from 'react-router-dom';

import TransactionList from '../TransactionList';
import TransactionTitle from '../TransactionTitle';
import { DateRange, DateRangeInput } from '@blueprintjs/datetime';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  dateRangeSelector,
  setDateRangeAction,
} from '../../redux/modules/filter';

import {
  isListReversedSelector,
  setListReversedAction,
} from '../../redux/modules/account';
import { isDarkModeSelector } from '../../redux/modules/user';
import { AppToaster } from '../AppToaster';

export default function Account() {
  let { iban } = useParams<any>();
  const dispatch = useDispatch();
  const dateRange = useSelector(dateRangeSelector);
  const isListReversed = useSelector(isListReversedSelector);
  const isDarkMode = useSelector(isDarkModeSelector);

  function onListReverse(e: any) {
    dispatch(setListReversedAction(!isListReversed));
  }

  // const transactions = useSelector(transactionsSelector);

  if (!iban) {
    return (
      <div
        style={{ backgroundColor: isDarkMode ? Colors.DARK_GRAY3 : 'white' }}
      >
        IBAN ERROR
      </div>
    );
  }

  function onDateChange(dateRange: DateRange) {
    dispatch(setDateRangeAction(dateRange));
  }

  function exportPdf() {
    if (
      !iban ||
      !dateRange?.length ||
      dateRange.length !== 2 ||
      !dateRange[0] ||
      !dateRange[1]
    ) {
      return AppToaster.error(
        'Veuillez selectionner une date de debut et de fin.',
      );
    }

    const startDate = moment(dateRange[0]).format('YYYY-MM-DD');
    const endDate = moment(dateRange[1]).format('YYYY-MM-DD');

    // console.log(dateRange[0], dateRange[1], iban);
    const url = `/api/v1/transactions/export?iban=${iban}&startDate=${startDate}&endDate=${endDate}`;
    window.open(url, '_blank');
    // dispatch(exportTransactionsAsPdf(dateRange[0], dateRange[1], iban));
  }

  return (
    <div style={{ backgroundColor: isDarkMode ? Colors.DARK_GRAY3 : 'white' }}>
      <div className='sticky'>
        <Header exportPdf={exportPdf} />
        <TransactionTitle />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 10,
        }}
      >
        <DateRangeInput
          formatDate={(date, locale) => moment(date).format('DD/MM/YYYY')}
          parseDate={(str) => moment(str).toDate()}
          locale='fr'
          localeUtils={MomentLocaleUtils}
          startInputProps={{ placeholder: 'Du' }}
          endInputProps={{ placeholder: 'Au' }}
          shortcuts={false}
          onChange={onDateChange}
          value={[dateRange?.[0] ?? null, dateRange?.[1] ?? null]}
        />

        <Switch
          style={{ marginLeft: 5, marginBottom: 0 }}
          checked={isListReversed}
          label='Liste Inversee'
          onChange={onListReverse}
        />
      </div>

      <TransactionList iban={iban} />
    </div>
  );
}
