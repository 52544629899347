import React, { useEffect } from 'react';

import _ from 'lodash';

import { ITransaction } from '@libat/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTransactions,
  transactionsOfAccountSelector,
} from '../redux/modules/transaction';
import Transaction from './Transaction';
import { dateRangeSelector } from '../redux/modules/filter';
import moment from 'moment';
import TransactionTotal from './TransactionTotal';
import { isListReversedSelector } from '../redux/modules/account';

export default function TransactionList({ iban }: { iban: string }) {
  const dispatch = useDispatch();
  const transactions = useSelector(transactionsOfAccountSelector(iban));
  const dateRange = useSelector(dateRangeSelector);
  const isListReversed = useSelector(isListReversedSelector);

  useEffect(() => {
    dispatch(fetchTransactions(iban));
  }, [dispatch, iban]);

  let trList: ITransaction[];
  if (isListReversed) {
    trList = transactions.reverse();
  } else {
    trList = transactions;
  }

  function getExpenseList() {
    return (
      <div style={{ padding: '10px' }}>
        {trList.map((el) => {
          const expenseData: ITransaction = _.cloneDeep(el);

          if (
            !dateRange ||
            !dateRange[0] ||
            !dateRange[1] ||
            moment(el.date).isBetween(dateRange[0], dateRange[1], 'day', '[]')
          ) {
            return (
              <Transaction
                key={expenseData.id}
                transactionId={expenseData.id}
              />
            );
          }

          return undefined;
        })}
      </div>
    );
  }

  return (
    <div>
      {isListReversed ? <TransactionTotal iban={iban} /> : undefined}
      {getExpenseList()}
      {isListReversed ? undefined : <TransactionTotal iban={iban} />}
    </div>
  );
}
