"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * LiBat Gestion
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelApiResponseToJSON = exports.ModelApiResponseFromJSONTyped = exports.ModelApiResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
function ModelApiResponseFromJSON(json) {
    return ModelApiResponseFromJSONTyped(json, false);
}
exports.ModelApiResponseFromJSON = ModelApiResponseFromJSON;
function ModelApiResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'code': !runtime_1.exists(json, 'code') ? undefined : json['code'],
        'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    };
}
exports.ModelApiResponseFromJSONTyped = ModelApiResponseFromJSONTyped;
function ModelApiResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'code': value.code,
        'type': value.type,
        'message': value.message,
    };
}
exports.ModelApiResponseToJSON = ModelApiResponseToJSON;
