import { createSelector } from 'reselect';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

import { UserApi, IUser } from '@libat/api';
import { ApiConfig } from '@libat/shared';
// import {UserApi} from "../../../../api/dist/apis";
// import {UserApi, IUser} from '@libat/api';
const userApi = new UserApi(ApiConfig);

//////////////////////////////////////////////////////
// Actions
//////////////////////////////////////////////////////
const FETCH_CURRENT_USER_START = 'user/FETCH_CURRENT_USER_START';
const FETCH_CURRENT_USER_DONE = 'user/FETCH_CURRENT_USER_DONE';
const LOGOUT = 'user/LOGOUT';
const LOGIN = 'user/LOGIN';
const SET_USERS = 'user/SET_USERS';
const SET_WRONG_CREDENTIALS = 'user/SET_WRONG_CREDENTIALS';
const SET_DARK_MODE = 'user/SET_DARK_MODE';

//////////////////////////////////////////////////////
// Action Creators
//////////////////////////////////////////////////////
const fetchCurrentUserStart = createAction(FETCH_CURRENT_USER_START)();
const fetchCurrentUserDone = createAction(FETCH_CURRENT_USER_DONE)();
export const login = createAction(LOGIN)<IUser>();
export const logout = createAction(LOGOUT)();
export const setUsers = createAction(SET_USERS)<IUser[]>();
export const setWrongCredentials = createAction(SET_WRONG_CREDENTIALS)();
export const setDarkMode = createAction(SET_DARK_MODE)<boolean>();

type RootAction = ActionType<
  | typeof fetchCurrentUserStart
  | typeof fetchCurrentUserDone
  | typeof login
  | typeof logout
  | typeof setUsers
  | typeof setWrongCredentials
  | typeof setDarkMode
>;

//////////////////////////////////////////////////////
// Reducer
//////////////////////////////////////////////////////
type UserState = Readonly<{
  isAuth: boolean;
  isDarkMode: boolean;
  isFetchingCurrentUser: boolean;
  isFetchingCurrentUserDone: boolean;
  isWrongCredentials: boolean;
  currentUser: IUser | null;
  users: IUser[];
}>;

const initialState: UserState = {
  isAuth: false,
  isDarkMode: true,
  isFetchingCurrentUser: false,
  isFetchingCurrentUserDone: false,
  isWrongCredentials: false,
  currentUser: null,
  users: [],
};

const userReducer = createReducer<UserState, RootAction>(initialState)
  .handleAction(fetchCurrentUserStart, (state, action) => {
    return { ...state, isFetchingCurrentUser: true };
  })
  .handleAction(fetchCurrentUserDone, (state, action) => {
    return { ...state, isFetchingCurrentUserDone: true };
  })
  .handleAction(login, (state, action) => {
    console.log('LOGIN');
    return {
      ...state,
      currentUser: action.payload,
      isAuth: true,
      isFetchingCurrentUser: false,
      isWrongCredentials: false,
    };
  })
  .handleAction(logout, (state, action) => {
    return {
      ...state,
      currentUser: null,
      isAuth: false,
      isFetchingCurrentUser: false,
    };
  })
  .handleAction(setUsers, (state, action) => {
    return { ...state, users: action.payload };
  })
  .handleAction(setWrongCredentials, (state, action) => {
    return { ...state, isWrongCredentials: true };
  })
  .handleAction(setDarkMode, (state, action) => {
    return { ...state, isDarkMode: action.payload };
  });

export default userReducer;

//////////////////////////////////////////////////////
// Selectors
//////////////////////////////////////////////////////
export const usersSelector = ({ user }: { user: UserState }) => user.users;
export const currentUserSelector = ({ user }: { user: UserState }) =>
  user.currentUser;
export const isAuthSelector = ({ user }: { user: UserState }) => user.isAuth;
export const isFetchingCurrentUserSelector = ({ user }: { user: UserState }) =>
  user.isFetchingCurrentUser;
export const isFetchingCurrentUserDoneSelector = ({
  user,
}: {
  user: UserState;
}) => user.isFetchingCurrentUserDone;
export const isUserWrongCredentialsSelector = ({ user }: { user: UserState }) =>
  user.isWrongCredentials;
export const isDarkModeSelector = ({ user }: { user: UserState }) =>
  user.isDarkMode;

export const userFromIdSelector = (userId: number | null) => {
  return createSelector(usersSelector, (users) => {
    if (userId === null) {
      return null;
    }

    return users.find((el) => el.id === userId);
  });
};

//////////////////////////////////////////////////////
// Action Helpers
//////////////////////////////////////////////////////

export function fetchCurrentUser() {
  return async (dispatch: any) => {
    try {
      const user = await userApi.getCurrentUser();
      // console.log('user', user);

      if (user.id) {
        dispatch(login(user));
      } else {
        dispatch(logout());
      }
    } catch (e) {
      console.log('Error', e);
      dispatch(logout());
    }
    dispatch(fetchCurrentUserDone());
  };
}

export function fetchUsers() {
  return async (dispatch: any) => {
    try {
      const users = await userApi.getUsers();
      dispatch(setUsers(users));
    } catch (e) {
      console.log(e);
    }
  };
}

export function startLogin(username: string, password: string) {
  return async (dispatch: any) => {
    try {
      const r = await userApi.login({ username, password });
      // console.log(r);
      dispatch(login(r.user));
    } catch (e) {
      console.log('Error', e);
      dispatch(setWrongCredentials());
    }
  };
}

export function startLogout() {
  return async (dispatch: any) => {
    try {
      await userApi.logout();
      dispatch(logout());
    } catch (e) {
      console.log('Error', e);
    }
  };
}
