import React, { useState } from 'react';

export default function withDataSubmitTimer(
  WrappedComponent: React.ComponentType<any> | string,
  submitData: (target: any) => void,
) {
  return function (props: any) {
    const [state, setState] = useState<{ timerId: number }>({ timerId: 0 });

    function startTimerUpdate(e: React.SyntheticEvent) {
      if (state.timerId) {
        clearTimeout(state.timerId);
      }

      const target = e.target as any;
      const timer = setTimeout(() => submitData(target ?? e), 1000);

      // @ts-ignore
      setState({ ...state, timerId: timer });
    }

    function doSubmitData(e: React.SyntheticEvent) {
      if (state.timerId) {
        clearTimeout(state.timerId);
      }

      const target = e.target as any;
      submitData(target ?? e);
    }

    return (
      <WrappedComponent
        onBlur={doSubmitData}
        onChange={startTimerUpdate}
        {...props}
      />
    );
  };
}
