import React from 'react';
import { ITransaction } from '@libat/api';
import { useSelector } from 'react-redux';
import { transactionByIdSelector } from '../redux/modules/transaction';
import TransactionRow from './TransactionRow';

export default function Transaction({
  transactionId,
}: {
  transactionId: number;
}) {
  const transaction: ITransaction | undefined = useSelector(
    transactionByIdSelector(transactionId),
  );

  if (!transaction) {
    return <div>Loading {transactionId}</div>;
  }

  const hasChildren = !!(
    transaction.childTransactions && transaction.childTransactions.length
  );

  return (
    <div>
      <TransactionRow
        transactionId={transactionId}
        isParentTransaction={true}
        hasChildren={hasChildren}
      />
      {transaction.childTransactions ? (
        transaction.childTransactions.map((child) => {
          return (
            <TransactionRow
              transactionId={child.id}
              key={child.id}
              isParentTransaction={false}
            />
          );
        })
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}
