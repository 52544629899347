import React from 'react';

import { getColumnWidth, TransactionColumn } from '@libat/shared';
import { Colors, Divider } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { isDarkModeSelector } from '../redux/modules/user';

export default function TransactionTitle() {
  const isDarkMode = useSelector(isDarkModeSelector);

  return (
    <div
      className='transactionTitle'
      style={{ backgroundColor: isDarkMode ? Colors.DARK_GRAY3 : 'white' }}
    >
      <div
        style={{ display: 'flex', fontWeight: 'bold', alignItems: 'center' }}
      >
        <div
          style={{
            width: getColumnWidth(TransactionColumn.Date) + 'px',
            minWidth: getColumnWidth(TransactionColumn.Date) + 'px',
          }}
        >
          Date
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.ValueDate) + 'px',
            minWidth: getColumnWidth(TransactionColumn.ValueDate) + 'px',
          }}
        >
          <div>Date</div>
          <div>Valeur</div>
        </div>
        <div
          style={{
            flexGrow: 1,
            minWidth: '100px',
          }}
        >
          Description
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.Note) + 'px',
            minWidth: getColumnWidth(TransactionColumn.Note) + 'px',
          }}
        >
          Note
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.Debit) + 'px',
            minWidth: getColumnWidth(TransactionColumn.Debit) + 'px',
            textAlign: 'center',
          }}
        >
          Debit
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.Credit) + 'px',
            minWidth: getColumnWidth(TransactionColumn.Credit) + 'px',
            textAlign: 'center',
          }}
        >
          Credit
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.Balance) + 'px',
            minWidth: getColumnWidth(TransactionColumn.Balance) + 'px',
            textAlign: 'center',
          }}
        >
          Balance
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.TvaDebit) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaDebit) + 'px',
            textAlign: 'center',
          }}
        >
          <div>Tva</div>
          <div>Debit</div>
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.TvaCredit) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaCredit) + 'px',
            textAlign: 'center',
          }}
        >
          <div>Tva</div>
          <div>Credit</div>
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.TvaInvestment) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaInvestment) + 'px',
            textAlign: 'center',
          }}
        >
          <div>Tva</div>
          <div>Invest.</div>
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.TvaPercent) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaPercent) + 'px',
            textAlign: 'center',
          }}
        >
          <div>Tva</div>
          <div>%</div>
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.TvaCheckBox) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaCheckBox) + 'px',
            textAlign: 'center',
          }}
        >
          Invest.
        </div>
      </div>

      <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
    </div>
  );
}
