import React from 'react';
import { getColumnWidth, TransactionColumn } from '@libat/shared';
import { Colors, Divider } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { totalsSelector } from '../redux/modules/transaction';
import { round } from '@libat/shared';
import { isListReversedSelector } from '../redux/modules/account';
import { isDarkModeSelector } from '../redux/modules/user';

interface TransactionTotalProps {
  iban: string;
}

export default function TransactionTotal({ iban }: TransactionTotalProps) {
  const totals = useSelector(totalsSelector(iban));
  const isListReversed = useSelector(isListReversedSelector);
  const isDarkMode = useSelector(isDarkModeSelector);

  return (
    <div
      className='transactionTitle'
      style={{ backgroundColor: isDarkMode ? Colors.DARK_GRAY3 : 'white' }}
    >
      {isListReversed ? undefined : (
        <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
      )}

      <div
        style={{ display: 'flex', fontWeight: 'bold', alignItems: 'center' }}
      >
        <div
          style={{
            width: getColumnWidth(TransactionColumn.Date) + 'px',
            minWidth: getColumnWidth(TransactionColumn.Date) + 'px',
          }}
        />
        <div
          style={{
            width: getColumnWidth(TransactionColumn.ValueDate) + 'px',
            minWidth: getColumnWidth(TransactionColumn.ValueDate) + 'px',
          }}
        />
        <div
          style={{
            flexGrow: 1,
            minWidth: '100px',
          }}
        />
        <div
          style={{
            width: getColumnWidth(TransactionColumn.Note) + 'px',
            minWidth: getColumnWidth(TransactionColumn.Note) + 'px',
          }}
        />
        <div
          style={{
            width: getColumnWidth(TransactionColumn.Debit) + 'px',
            minWidth: getColumnWidth(TransactionColumn.Debit) + 'px',
            textAlign: 'center',
          }}
        >
          {round(totals.debit, 2)}
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.Credit) + 'px',
            minWidth: getColumnWidth(TransactionColumn.Credit) + 'px',
            textAlign: 'center',
          }}
        >
          {round(totals.credit, 2)}
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.Balance) + 'px',
            minWidth: getColumnWidth(TransactionColumn.Balance) + 'px',
            textAlign: 'center',
          }}
        >
          {round(totals.balance, 2)}
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.TvaDebit) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaDebit) + 'px',
            textAlign: 'center',
          }}
        >
          {round(totals.tvaDebit, 2)}
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.TvaCredit) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaCredit) + 'px',
            textAlign: 'center',
          }}
        >
          {round(totals.tvaCredit, 2)}
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.TvaInvestment) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaInvestment) + 'px',
            textAlign: 'center',
          }}
        >
          {round(totals.tvaInvestment, 2)}
        </div>
        <div
          style={{
            width: getColumnWidth(TransactionColumn.TvaPercent) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaPercent) + 'px',
            textAlign: 'center',
          }}
        />
        <div
          style={{
            width: getColumnWidth(TransactionColumn.TvaCheckBox) + 'px',
            minWidth: getColumnWidth(TransactionColumn.TvaCheckBox) + 'px',
            textAlign: 'center',
          }}
        />
      </div>

      {isListReversed ? (
        <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
      ) : undefined}
    </div>
  );
}
