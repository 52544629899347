"use strict";
exports.__esModule = true;
exports.getColumnWidth = exports.TransactionColumn = exports.DEFAULT_TVA_RATE = void 0;
exports.DEFAULT_TVA_RATE = 8.1;
var TransactionColumn;
(function (TransactionColumn) {
    TransactionColumn[TransactionColumn["Date"] = 0] = "Date";
    TransactionColumn[TransactionColumn["ValueDate"] = 1] = "ValueDate";
    TransactionColumn[TransactionColumn["Description"] = 2] = "Description";
    TransactionColumn[TransactionColumn["Note"] = 3] = "Note";
    TransactionColumn[TransactionColumn["Debit"] = 4] = "Debit";
    TransactionColumn[TransactionColumn["Credit"] = 5] = "Credit";
    TransactionColumn[TransactionColumn["Balance"] = 6] = "Balance";
    TransactionColumn[TransactionColumn["TvaDebit"] = 7] = "TvaDebit";
    TransactionColumn[TransactionColumn["TvaCredit"] = 8] = "TvaCredit";
    TransactionColumn[TransactionColumn["TvaInvestment"] = 9] = "TvaInvestment";
    TransactionColumn[TransactionColumn["TvaPercent"] = 10] = "TvaPercent";
    TransactionColumn[TransactionColumn["TvaCheckBox"] = 11] = "TvaCheckBox";
})(TransactionColumn = exports.TransactionColumn || (exports.TransactionColumn = {}));
function getColumnWidth(column) {
    if (column === TransactionColumn.Date) {
        return 90;
    }
    else if (column === TransactionColumn.ValueDate) {
        return 90;
    }
    else if (column === TransactionColumn.Description) {
        return 500;
    }
    else if (column === TransactionColumn.Note) {
        return 200;
    }
    else if (column === TransactionColumn.Debit) {
        return 100;
    }
    else if (column === TransactionColumn.Credit) {
        return 100;
    }
    else if (column === TransactionColumn.Balance) {
        return 100;
    }
    else if (column === TransactionColumn.TvaDebit) {
        return 75;
    }
    else if (column === TransactionColumn.TvaCredit) {
        return 75;
    }
    else if (column === TransactionColumn.TvaInvestment) {
        return 75;
    }
    else if (column === TransactionColumn.TvaCheckBox) {
        return 50;
    }
    else if (column === TransactionColumn.TvaPercent) {
        return 50;
    }
    else {
        throw new Error("Column not implemented: " + column);
    }
}
exports.getColumnWidth = getColumnWidth;
