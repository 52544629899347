import { Intent, IToaster, Position, Toaster } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

interface IToasterExtended extends IToaster {
  error: (msg: string) => void;
  success: (msg: string) => void;
}

export const AppToaster = Toaster.create({
  position: Position.TOP,
}) as IToasterExtended;

AppToaster.error = (msg: string) => {
  AppToaster.show({
    message: msg,
    icon: IconNames.CROSS,
    intent: Intent.DANGER,
  });
};

AppToaster.success = (msg: string) => {
  AppToaster.show({
    message: msg,
    icon: IconNames.TICK,
    intent: Intent.SUCCESS,
  });
};
