"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * LiBat Gestion
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IInvoiceParamsToJSON = exports.IInvoiceParamsFromJSONTyped = exports.IInvoiceParamsFromJSON = void 0;
const runtime_1 = require("../runtime");
function IInvoiceParamsFromJSON(json) {
    return IInvoiceParamsFromJSONTyped(json, false);
}
exports.IInvoiceParamsFromJSON = IInvoiceParamsFromJSON;
function IInvoiceParamsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'account': !runtime_1.exists(json, 'account') ? undefined : json['account'],
        'address': !runtime_1.exists(json, 'address') ? undefined : json['address'],
        'buildingNumber': !runtime_1.exists(json, 'buildingNumber') ? undefined : json['buildingNumber'],
        'zip': !runtime_1.exists(json, 'zip') ? undefined : json['zip'],
        'city': !runtime_1.exists(json, 'city') ? undefined : json['city'],
        'country': !runtime_1.exists(json, 'country') ? undefined : json['country'],
        'amount': json['amount'],
        'currency': json['currency'],
        'reference': !runtime_1.exists(json, 'reference') ? undefined : json['reference'],
    };
}
exports.IInvoiceParamsFromJSONTyped = IInvoiceParamsFromJSONTyped;
function IInvoiceParamsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'account': value.account,
        'address': value.address,
        'buildingNumber': value.buildingNumber,
        'zip': value.zip,
        'city': value.city,
        'country': value.country,
        'amount': value.amount,
        'currency': value.currency,
        'reference': value.reference,
    };
}
exports.IInvoiceParamsToJSON = IInvoiceParamsToJSON;
