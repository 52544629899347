import { ItemListRenderer, Select } from '@blueprintjs/select';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

interface StringSelectorProps {
  items: string[];
  defaultValue: string;
  onChange: (item: string) => void;
  style?: CSSProperties;
  filterable?: boolean;
}

const SelectString = Select.ofType<string>();

export default function StringSelector({
  items,
  defaultValue,
  onChange,
  style,
  filterable = true,
}: StringSelectorProps) {
  const [state, setState] = useState({ selected: defaultValue });

  useEffect(() => {
    setState((s) => ({ ...s, selected: defaultValue }));
  }, [defaultValue]);

  function onItemSelect(item: string) {
    setState({ ...state, selected: item });
    onChange(item);
  }

  function renderItem(item: string, { handleClick }: any) {
    return <MenuItem key={item} text={item} onClick={handleClick} />;
  }

  const renderMenu: ItemListRenderer<string> = ({
    filteredItems,
    itemsParentRef,
    query,
    renderItem,
  }) => {
    const renderedItems = filteredItems
      .map(renderItem)
      .filter((item) => item != null);
    return (
      <Menu ulRef={itemsParentRef} style={{ maxHeight: 400, overflow: 'auto' }}>
        {renderedItems}
      </Menu>
    );
  };

  function filterItems(
    query: string,
    item: string,
    index?: number,
    exactMatch?: boolean,
  ) {
    const normalizedTitle = item.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
  }

  return (
    <SelectString
      // className='bp3-select-popover'
      items={items}
      onItemSelect={onItemSelect}
      itemListRenderer={renderMenu}
      itemRenderer={renderItem}
      itemPredicate={filterItems}
      noResults={<MenuItem disabled={true} text='No results.' />}
      popoverProps={{ fill: true }}
      filterable={filterable}
    >
      <Button
        style={style}
        className='button-flex-grow'
        small={true}
        text={state.selected}
        rightIcon={IconNames.CARET_DOWN}
        fill={true}
      />
    </SelectString>
  );
}
