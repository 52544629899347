// import { createSelector } from 'reselect';
import { createAction, createReducer, ActionType } from 'typesafe-actions';
import moment from 'moment';
import { DateRange } from '@blueprintjs/datetime';

//////////////////////////////////////////////////////
// Actions
//////////////////////////////////////////////////////
const SET_DATERANGE = 'filter/SET_DATERANGE';

//////////////////////////////////////////////////////
// Action Creators
//////////////////////////////////////////////////////
export const setDateRangeAction = createAction(SET_DATERANGE)<DateRange>();

type RootAction = ActionType<typeof setDateRangeAction>;

//////////////////////////////////////////////////////
// Reducer
//////////////////////////////////////////////////////
type FilterState = Readonly<{
  dateRange: DateRange;
}>;

const initialState: FilterState = {
  dateRange: [moment().startOf('year').toDate(), moment().toDate()],
};

const filterReducer = createReducer<FilterState, RootAction>(
  initialState,
).handleAction(setDateRangeAction, (state, action) => {
  const dateRange = action.payload;

  if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
    let date = dateRange[0];
    dateRange[0] = moment
      .utc(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
      .toDate();

    date = dateRange[1];
    dateRange[1] = moment
      .utc(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
      .toDate();
  }

  return { ...state, dateRange: dateRange };
});

export default filterReducer;

//////////////////////////////////////////////////////
// Selectors
//////////////////////////////////////////////////////
export const dateRangeSelector = ({ filter }: { filter: FilterState }) =>
  filter.dateRange;

//////////////////////////////////////////////////////
// Action Helpers
//////////////////////////////////////////////////////
// export function setDateRange(dateRange: DateRange) {
//   return async (dispatch: any) => {
//     try {
//       setDateRangeAction(dateRange);
//       // dispatch()
//     } catch (e)  {
//       console.log(e);
//     }
//   }
// }
