"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * LiBat Gestion
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IAccountToJSON = exports.IAccountFromJSONTyped = exports.IAccountFromJSON = void 0;
const runtime_1 = require("../runtime");
function IAccountFromJSON(json) {
    return IAccountFromJSONTyped(json, false);
}
exports.IAccountFromJSON = IAccountFromJSON;
function IAccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'iban': json['iban'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'visibleForVisitor': json['visibleForVisitor'],
    };
}
exports.IAccountFromJSONTyped = IAccountFromJSONTyped;
function IAccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'iban': value.iban,
        'description': value.description,
        'visibleForVisitor': value.visibleForVisitor,
    };
}
exports.IAccountToJSON = IAccountToJSON;
